<template>
  <v-card>
    <v-card-title>
      Administrators Data Table
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by username"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="admins"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="user.id"
      show-expand
      class="elevation-1"
    > 

    <template v-slot:[`item.user.id`]="{ item }">
        <v-chip
          color="grey"
          dark
        >
          {{ item.user.id }}
        </v-chip>
    </template>

    <template v-slot:[`item.user.is_bot`]="{ item }">
      <template>
        <v-chip v-if="item.user.is_bot == true"
          color="orange"
          dark
        >
          {{ item.user.is_bot }}
        </v-chip>
        <v-chip v-else-if="item.user.is_bot == false"
          color="green"
          dark
        >
          {{ item.user.is_bot }}
        </v-chip>
      </template>
    </template>

    <template v-slot:[`item.user.language_code`]="{ item }">
      <v-chip v-if="item.user.language_code != null">
        {{ item.user.language_code }}
      </v-chip>
    </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <h4 class="mt-4 mb-2" style="color: #448aff;"><v-icon style="color: #448aff;">mdi-account-group</v-icon> Groups of user "{{ item.user.username }}"</h4>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th>Group ID</th>
                  <th>Group Name</th>
                  <th>Group Creator</th>
                  <th>Group Link</th>
                  <th>Course Name</th>
                  <th>Degree Type</th>
                  <th>Permissions</th>
                  <th>Custom Title</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="groupItem in item.groups" :key="groupItem.id">
                  <td><v-chip outlined>{{ groupItem.group.id }}</v-chip></td>
                  <td>{{ groupItem.group.group_title }}</td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on">
                        {{groupItem.group.creator.first_name}} {{groupItem.group.creator.last_name}}
                        </v-chip>
                      </template>
                      <span>Username: {{groupItem.group.creator.username}}, ID: {{groupItem.group.creator.id}}, is_bot: {{groupItem.group.creator.is_bot}}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      fab
                      x-small
                      :href="groupItem.group.invite_link"
                      target="_blank"
                    >
                    <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ groupItem.group.course_name }}</td>
                  <td><v-chip color="orange">{{ groupItem.group.degree_type }}</v-chip></td>
                  <td>
                    <v-chip v-if="groupItem.permissions.can_invite_users == true"
                      class="ma-2"
                      small
                      color="blue"
                    >
                      invite-users
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_be_edited == true"
                      class="ma-2"
                      small
                      color="red"
                    >
                      edit
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_manage_chat == true"
                      class="ma-2"
                      small
                      color="pink"
                    >
                      manage-chat
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_delete_messages == true"
                      class="ma-2"
                      small
                      color="orange"
                    >
                      delete-message
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_promote_members == true"
                      class="ma-2"
                      small
                      color="yellow"
                    >
                      promote-members
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_pin_messages == true"
                      class="ma-2"
                      small
                      color="green"
                    >
                      pin-messages
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_change_info == true"
                      class="ma-2"
                      small
                      color="purple"
                    >
                      change-info
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_manage_voice_chats == true"
                      class="ma-2"
                      small
                    >
                      manage-voice-chats
                    </v-chip>
                    <v-chip v-if="groupItem.permissions.can_restrict_members == true"
                      class="ma-2"
                      small
                    >
                      restrict-members
                    </v-chip>
                  </td>
                  <td>
                    <v-chip v-if="groupItem.custom_title == null || groupItem.custom_title == ''">-</v-chip>
                    <v-chip v-else><span>{{groupItem.custom_title}}</span></v-chip>
                  </td>
                  <td>
                    <v-chip
                    v-if="groupItem.status == 'creator'"
                      class="ma-2"
                      color="orange"
                      text-color="white"
                    >
                      Creator
                      <v-icon right>
                        mdi-account-check
                      </v-icon>
                    </v-chip>
                    <v-chip
                    v-if="groupItem.status == 'administrator'"
                      class="ma-2"
                      color="blue"
                      text-color="white"
                    >
                      Administrator
                      <v-icon right>
                        mdi-account
                      </v-icon>
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
  import admins from '../admins';
  console.log(admins)

  export default {
      data() {
          return {
            expanded: [],
            singleExpand: false,
            search: '',
            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'user.id' },
                { text: 'Username', value: 'user.username' },
                { text: 'First Name', value: 'user.first_name'},
                { text: 'Last Name', value: 'user.last_name'},
                { text: 'is_bot', value: 'user.is_bot' },
                { text: 'language_code', value: 'user.language_code' },
                { text: '', value: 'data-table-expand' },
            ],
            admins: admins,
          }
      }
  }
</script>
